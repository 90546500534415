// MONTSERRAT

@font-face {
  font-family: 'montserrat-regular';
  src: url('../assets/fonts/Montserrat/montserrat-v15-latin-regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-italic';
  src: url('../assets/fonts/Montserrat/montserrat-v15-latin-italic.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-bold';
  src: url('../assets/fonts/Montserrat/montserrat-v15-latin-700.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-bold-italic';
  src: url('../assets/fonts/Montserrat/montserrat-v15-latin-700italic.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'press-start-regular';
  src: url('../assets/fonts/PressStart2P/press-start-2p-v9-latin-regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'pixeboy';
  src: url('../assets/fonts/pixeboy/Pixeboy-z8XGD.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'syncopate-bold';
  src: local('syncopate-bold'), url('../assets/fonts/Syncopate-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'basier-square-mono-regular';
  src: local('basier-square-mono-regular'), url('../assets/fonts/basier-square-mono-regular.woff') format('woff');
  font-style: normal;
}
